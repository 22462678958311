import { T_Error } from "src/model/errorModel";
import { T_sendApiResponseData, sendApi } from ".";
import { T_Place } from "src/model/placeModel";

async function productList(data?: any) {
    const response = await sendApi({
      method: "post",
      url: "/product/list",
      data: {
        ...data,
      },
    });
    console.log(response)
    return response.data;
  }

async function productCreate(data1?:T_Place){

  const response = await sendApi({
    method: "post",
    url: "/product/insert",
    data: {
      ...data1,
    },
  });

  
  return response.data;
}  

async function productDelete(data1?:T_Place){

  const response = await sendApi({
    method: "post",
    url: "/product/delete",
    data: {
      ...data1,
    },
  });

  return response.data;
}  


const product = {
    productList,
    productCreate,
    productDelete,
  };
  export default product;

