import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from "@coreui/react";
import { ReactNode, createContext, useCallback, useContext, useState } from "react";

export type T_alertButton = {
  text: string;
  color?: string;
  onPress?: () => void;
};

export type T_alert = {
  title?: string;
  msg: string;
  onHide?: () => void;
  buttonList?: T_alertButton[];
  visible : boolean;
};

export type T_setAlert = (alert:T_alert) => void;

export const initialAlert: T_alert = {
  msg: "",
  visible : false,
};


const AlertContext = createContext(initialAlert);


export function useAlert() {
  const Context: any = useContext(AlertContext);
  const alert: T_alert = Context.alert;
  const setAlert: T_setAlert = Context.setAlert;
  const result = { alert, setAlert };
  return result;
}

export function AlertContextProvider({children}: { children: ReactNode }){
    const [alert, setAlert] = useState(false);
    const value : any  = {
      alert,
      setAlert,
    }
  return (
    <AlertContext.Provider value={value}>
      {children}
    </AlertContext.Provider>
  );
}

export function GlobalAlert() {
    const {alert,setAlert } = useAlert();
  
    const hide = useCallback(() => {
      if (alert.onHide) {
        alert.onHide();
      }
      setAlert(initialAlert);
    }, [alert, setAlert]);

    
    return (
      <CModal
      visible={alert.visible}
      onClose={() => setAlert({...alert,visible:false})}
      aria-labelledby="LiveDemoExampleLabel"
      >
      <CModalHeader  color="info" style={{background:"#4287f5",color:"#fffffff"}} >
          <CModalTitle style={{color:"#ffffff"}}id="LiveDemoExampleLabel">{alert.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
          <p>{alert.msg}</p>
      </CModalBody>
      <CModalFooter>
          {alert.buttonList?.map((item,idx)=>{
            return(
              <CButton key={idx} color={item.color} onClick={item.onPress}>{item.text}</CButton>
            )
          })}
          {alert.buttonList == null?<CButton onClick={hide}>예</CButton>:""}
      </CModalFooter>
      </CModal>
        );
  }