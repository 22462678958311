import { T_Error } from "src/model/errorModel";
import { T_sendApiResponseData, sendApi } from ".";
import { T_Place } from "src/model/placeModel";

async function placeList(data?: any) {
    const response = await sendApi({
      method: "post",
      url: "/place/list",
      data: {
        ...data,
      },
    });
    console.log(response)
    return response.data;
  }

async function placeCreate(data1?:T_Place){

  const response = await sendApi({
    method: "post",
    url: "/place/insert",
    data: {
      ...data1,
    },
  });

  
  return response.data;
}  

async function placeDelete(data1?:T_Place){

  const response = await sendApi({
    method: "post",
    url: "/place/delete",
    data: {
      ...data1,
    },
  });

  return response.data;
}  


const place = {
    placeList,
    placeCreate,
    placeDelete,
  };
  export default place;

