import { T_Inquery } from "src/model/InqueryModel";
import { sendApi } from ".";

async function inqueryList(data?: any) {

    const response = await sendApi({
      method: "post",
      url: "/inquery/list",
      data: {
        ...data,
      },
    });

    return response.data;
  }

async function inqueryCreate(data1?:T_Inquery){

  console.log("inquerydata:",data1);
  const response = await sendApi({
    method: "post",
    url: "/inquery/insert",
    data: {
      ...data1,
    },
  });

  return response.data;
}  


async function inqueryEdit(data1?:T_Inquery){

    console.log("inquerydata:",data1);
    const response = await sendApi({
      method: "post",
      url: "/inquery/edit",
      data: {
        ...data1,
      },
    });
  
    return response.data;
  }  

const inquery = {
    inqueryList,
    inqueryCreate,
    inqueryEdit,
  };
  export default inquery;
