import { AlertContextProvider, GlobalAlert } from "./AlertContextProvider";
import { AppStateContext } from "./appState";
import { GlobalProgressDialog, ProgressContextProvider } from "./ProgressContextProvider";

export type T_ContextsProps = {
    children: JSX.Element;
  };
  
  function Contexts({ children }: T_ContextsProps) {
    return (
        <AppStateContext>
        <ProgressContextProvider>
        <GlobalProgressDialog />
          <AlertContextProvider>
            <GlobalAlert />
            {children}
            </AlertContextProvider>
        </ProgressContextProvider>
        </AppStateContext>
    );
  }
  
  export default Contexts;
  