import { T_Error } from "src/model/errorModel";
import { T_sendApiResponseData, sendApi } from ".";
import { T_Video } from "src/model/videoModel";
import { T_Code } from "src/model/codeModel";

async function codeList(data?: any) {

    const response = await sendApi({
      method: "post",
      url: "/code/list",
      data: {
        ...data,
      },
    });

    return response.data;
  }

async function codeCreate(data1?:T_Code){

  const response = await sendApi({
    method: "post",
    url: "/code/insert",
    data: {
      ...data1,
    },
  });

  return response.data;
}  
async function codeEdit(data1?:T_Code){

  const response = await sendApi({
    method: "post",
    url: "/code/update",
    data: {
      ...data1,
    },
  });

  return response.data;
}  
async function codeDelete(data1?:T_Code){

  
  const response = await sendApi({
    method: "post",
    url: "/code/delete",
    data: {
      ...data1,
    },
  });

  return response.data;
}  

const error = {
    codeList,
    codeCreate,
    codeEdit,
    codeDelete,
  };
  export default error;

