import { T_Error } from "src/model/errorModel";
import { T_sendApiResponseData, sendApi } from ".";

async function errorList(data?: any) {

    const response = await sendApi({
      method: "post",
      url: "/error/list",
      data: {
        ...data,
      },
    });

    return response.data;
  }

async function errorCreate(data1?:T_Error){

  console.log("errordata:",data1);
  const response = await sendApi({
    method: "post",
    url: "/error/insert",
    data: {
      ...data1,
    },
  });

  return response.data;
} 

async function errorUpdate(data1?:T_Error){

  console.log("errordata update:",data1);
  const response = await sendApi({
    method: "post",
    url: "/error/update",
    data: {
      ...data1,
    },
  });

  return response.data;
}  

async function errorDelete(data1?:T_Error){

  console.log("errordata delete:",data1);
  const response = await sendApi({
    method: "post",
    url: "/error/delete",
    data: {
      ...data1,
    },
  });

  return response.data;
}  

const error = {
    errorList,
    errorCreate,
    errorUpdate,
    errorDelete,
  };
  export default error;

