import axios, { AxiosRequestConfig } from "axios";
import error from "./error";
import inquery from "./inquery";
import video from "./video";
import trouble from "./trouble";
import param from "./param";
import code from "./code";
import place from "./place";
import product from "./product";

export function initApis() {
  axios.defaults.baseURL = "https://as.islnc.co.kr";
//  axios.defaults.baseURL = "localhost:4001";
  axios.defaults.withCredentials = true;
  }
  
  export type T_sendApiConfig = {
    method: "get" | "put" | "post" | "delete";
    url: string;
    data?: any;
    isForm?: boolean;
  };
  
  export type T_sendApiResponseData = {
    isSuccess: boolean;
    msg: string;
    data?: any;
  };
  
  export async function sendApi(config: T_sendApiConfig) {
    //const {user} = useUser();
  //  if(user?.accessToken) axios.defaults.headers.common['TOKEN'] = user?.accessToken;
      if (config.isForm) {
        const formData = new FormData();
        
        for (const key in config.data) {
          if(key=="filelist"){
            for (const file of config.data[key]) {
              formData.append('filelist', file);
            }

          }else{
           formData.append(key, config.data[key]);
          }
        }

        console.log("formData:",formData)
        
        const axiosConfig: AxiosRequestConfig = {
          method: config.method,
          data: formData,
          url : config.url,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };

        const response = await axios(axiosConfig);
        const data = response.data;
        data.isSuccess = data?.code === "1";
        data.msg = data?.message || "";
        delete data.code;
        delete data.message;
        const newData: T_sendApiResponseData = data;
        const newResponse = {
          ...response,
          data: newData,
        };
        return newResponse;
      } else{

        const axiosConfig: AxiosRequestConfig = {
          method: config.method,
          url : config.url,
          data: config.data,
          headers: {
            'TOKEN':''
          }          
        };
        const response = await axios(axiosConfig);
        const data = response.data;
        console.log("respones:",response.data);
        data.isSuccess = data?.code === "1";
        data.msg = data?.message || "";
        delete data.code;
        delete data.message;
        const newData: T_sendApiResponseData = data;
        const newResponse = {
          ...response,
          data: newData,
        };
        return newResponse;
  }
  }

  
const apis = {
  error,
  inquery,
  video,
  trouble,
  param,
  code,
  place,
  product,
};

export default apis;
