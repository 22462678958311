import { T_Error } from "src/model/errorModel";
import { T_sendApiResponseData, sendApi } from ".";
import { T_Trouble } from "src/model/troubleModel";

async function troubleList(data?: any) {
    const response = await sendApi({
      method: "post",
      url: "/trouble/list",
      data: {
        ...data,
      },
    });
    console.log(response)
    return response.data;
  }

async function troubleCreate(data1?:T_Trouble){

  const response = await sendApi({
    method: "post",
    url: "/trouble/insert",
    isForm:true,
    data: {
      ...data1,
    },
  });

  console.log(response.data)
  return response.data;
}  

async function troubleDelete(data1?:T_Trouble){

  const response = await sendApi({
    method: "post",
    url: "/trouble/delete",
    data: {
      ...data1,
    },
  });
  return response.data;
}  


const trouble = {
    troubleList,
    troubleCreate,
    troubleDelete,
  };
  export default trouble;

