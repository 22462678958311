import { T_Error } from "src/model/errorModel";
import { T_sendApiResponseData, sendApi } from ".";
import { T_Trouble } from "src/model/troubleModel";
import { T_Param } from "src/model/paramModle";

async function paramList(data?: any) {
    const response = await sendApi({
      method: "post",
      url: "/param/list",
      data: {
        ...data,
      },
    });
    return response.data;
  }

async function paramCreate(data1?:T_Param){

  const response = await sendApi({
    method: "post",
    url: "/param/insert",
    data: {
      ...data1,
    },
  });

  
  return response.data;
} 

async function paramUpdate(data1?:T_Param){

  const response = await sendApi({
    method: "post",
    url: "/param/update",
    data: {
      ...data1,
    },
  });

  
  return response.data;
}  


async function paramDelete(data1?:T_Param){

  const response = await sendApi({
    method: "post",
    url: "/param/delete",
    data: {
      ...data1,
    },
  });

  return response.data;
}  


const trouble = {
    paramList,
    paramCreate,
    paramUpdate,
    paramDelete,
  };
  export default trouble;

