import { T_Error } from "src/model/errorModel";
import { T_sendApiResponseData, sendApi } from ".";
import { T_Video } from "src/model/videoModel";

async function videoList(data?: any) {

    const response = await sendApi({
      method: "post",
      url: "/video/list",
      data: {
        ...data,
      },
    });

    return response.data;
  }

async function videoCreate(data1?:T_Video){

  const response = await sendApi({
    method: "post",
    url: "/video/insert",
    data: {
      ...data1,
    },
  });

  return response.data;
}  
async function videoEdit(data1?:T_Video){

  const response = await sendApi({
    method: "post",
    url: "/video/update",
    data: {
      ...data1,
    },
  });

  return response.data;
}  
async function videoDelete(data1?:T_Video){

  console.log("errordata:",data1);
  
  const response = await sendApi({
    method: "post",
    url: "/video/delete",
    data: {
      ...data1,
    },
  });

  return response.data;
}  

const error = {
    videoList,
    videoCreate,
    videoEdit,
    videoDelete,
  };
  export default error;

